import { Card, Col, List, Row, Table } from "antd";
import React from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { FirebaseContext, IFirebaseContext } from "../../FirebaseContext";
import { Loader } from "../../FirebaseProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRunning,
  faBiking,
  faSwimmer,
  faWalking,
} from "@fortawesome/free-solid-svg-icons";

export interface IndividualOverViewProps {}

export const setTitle = (key: string) => {
  switch (key) {
    case "cycling":
      return (
        <div>
          Beste fietsers <FontAwesomeIcon icon={faBiking} />
        </div>
      );
    case "swimming":
      return (
        <div>
          Beste zwemmers <FontAwesomeIcon icon={faSwimmer} />
        </div>
      );
    case "running":
      return (
        <div>
          Beste lopers <FontAwesomeIcon icon={faRunning} />
        </div>
      );
    case "walking":
      return (
        <div>
          Beste wandelaars <FontAwesomeIcon icon={faWalking} />
        </div>
      );
  }
};

export const sortObj = (obj: any) => {
  return Object.keys(obj)
    .sort()
    .reduce(function (result: any, key) {
      result[key] = obj[key];
      return result;
    }, {});
};

const IndividualOverView: React.FC<IndividualOverViewProps> = () => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const topIndividualRef = context.store
    .collection("top_prestaties")
    .doc("gebruikers");
  const [
    topIndividualDoc,
    loadingTopIndividualDoc,
    topIndividualError,
  ] = useDocumentData(topIndividualRef);

  const userDistancesCollection = context.store
    .collection("gebruiker_doelen")
    .orderBy("totalGoal.distanceInSportKm", "desc");

  const [
    userDistancesValue,
    loadingUserDistances,
    userDistancesError,
  ] = useCollectionData(userDistancesCollection);

  const Loading = () =>
    loadingTopIndividualDoc &&
    !topIndividualDoc &&
    loadingUserDistances &&
    !userDistancesValue ? (
      <Loader />
    ) : null;

  const Error = () =>
    (!loadingTopIndividualDoc && !loadingUserDistances && topIndividualError) ||
    userDistancesError ? (
      <p style={{ color: "white" }}>
        Er is iets misgegaan, probeer het opnieuw.
      </p>
    ) : null;

  return (
    <div>
      <div className="jumbotron text-center">
        <h2>Individuele wedstrijd</h2>
      </div>
      <div className="container">
        <Loading />
        {!loadingTopIndividualDoc &&
          !loadingTopIndividualDoc &&
          topIndividualDoc &&
          userDistancesValue && (
            <div className="card-wrapper">
              <Row gutter={16}>
                {Object.keys(sortObj(topIndividualDoc as any)).map(
                  (key, index) => (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                      <Card title={setTitle(key)} className="m-2">
                        <List
                          className="individual-list"
                          pagination={{
                            pageSize: 5,
                            responsive: true,
                            simple: true,
                          }}
                          dataSource={(topIndividualDoc as any)[key]}
                          renderItem={(item: any, listKey) => (
                            <List.Item key={listKey}>
                              <List.Item.Meta
                                title={<a>{item.name}</a>}
                                description={
                                  <span
                                    style={{
                                      fontStyle: "italic",
                                      color: "#007bff",
                                    }}
                                  >
                                    {item.company}
                                  </span>
                                }
                              />
                              <div>{key === 'swimming' ? Math.round(item.distance / 10) : item.distance}km</div>
                            </List.Item>
                          )}
                        ></List>
                      </Card>
                    </Col>
                  )
                )}
              </Row>
            </div>
          )}
        <Error />
      </div>
    </div>
  );
};

export default IndividualOverView;
