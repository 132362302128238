import { Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { FirebaseContext, IFirebaseContext } from "../../FirebaseContext";
import { Loader } from "../../FirebaseProvider";
import Register from "../../RegisterComponent";
import "./style.scss";
import ProfileOverview from "../ProfileOverview/ProfileOverview";

export enum StravaStatus {
  CONNECTED = "Connected",
  CONNECTING = "Connecting",
  NOT_CONNECTED = "Not connected",
  CONNECTING_ERROR = "Connecting error",
}

export enum ProgressStatus {
  InProgress = "InProgress",
  Uninitialized = "Uninitialized",
  Done = "Done",
  Error = "Error",
}

export enum ProfileRegistrationStatus {
  COMPLETE_PROFILE = "completed profile",
  INCOMPLETE_PROFILE = "uncompleted profile",   
  Uninitialized = "Uninitialized",
}

const ProfileScreen = () => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const [user] = useAuthState(context.auth);
  const [profileStatus, setProfileStatus] = useState<ProfileRegistrationStatus>(
    ProfileRegistrationStatus.Uninitialized
  );
  const [
    getUserProgressStatus,
    setGetUserProgressStatus,
  ] = useState<ProgressStatus>(ProgressStatus.Uninitialized);
  const userCollection = context.store.collection("gebruikers");
  const userDocument = userCollection.doc(user.uid);
  const [stravaStatus, setStravaStatus] = useState(StravaStatus.NOT_CONNECTED);
  const [userDoc, loadingUserDoc, userDocError] = useDocumentData(userDocument);

  useEffect(() => {
    // remove listener on user document
    const unsubscribe = userDocument.onSnapshot(
      (res) => {
        // do something when we unsubscribe the change listener
        // TODO: maybe remove strava information from state when we unmount?
        // TODO: Nadenken of dit wel effectief nodig is? moeten we hier luisteren naar real time changes?
        // If not gebruik useDocumentDataOnce
      },
      (error) => {
        // do something when an error eccors here
      }
    );
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (loadingUserDoc) setGetUserProgressStatus(ProgressStatus.InProgress);
    if (!loadingUserDoc && userDocError)
      setGetUserProgressStatus(ProgressStatus.Error);
    if (!loadingUserDoc) {
      setGetUserProgressStatus(ProgressStatus.Done);
      setProfileStatus(
        userDoc
          ? ProfileRegistrationStatus.COMPLETE_PROFILE
          : ProfileRegistrationStatus.INCOMPLETE_PROFILE
      );
    }
  }, [loadingUserDoc, userDoc]);

  switch (getUserProgressStatus) {
    case ProgressStatus.InProgress:
      return <Loader/>;
    case ProgressStatus.Done:
      switch (profileStatus) {
        case ProfileRegistrationStatus.INCOMPLETE_PROFILE:
          return (
            <Register
              stravaStatus={stravaStatus}
              statusCallback={setStravaStatus}
              setProfileStatus={setProfileStatus}
            />
          );
        case ProfileRegistrationStatus.COMPLETE_PROFILE:
          return <ProfileOverview />
        default:
          return null;
      }
    case ProgressStatus.Error:
      return (
        <Result
          status="warning"
          title="Er is iets misgegaan, probeer het opnieuw."
        />
      );
    default:
      return null;
  }
};

export default ProfileScreen;
