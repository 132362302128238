import { Card, Col, List, Row, Table } from "antd";
import React, { useEffect } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { FirebaseContext, IFirebaseContext } from "../../FirebaseContext";
import { Loader } from "../../FirebaseProvider";
import { setTitle, sortObj } from "../IndividualOverView/IndividualOverview";

export interface CompanyOverviewProps {}

const columns = [
  {
    title: "Bedrijf",
    dataIndex: "name",
  },
  {
    title: "Afstand (km)",
    dataIndex: "distance",
  },
];

const topPerformanceColumns = [
  {
    title: "Bedrijf",
    dataIndex: "name",
  },
  {
    title: "Afstand (Oecos)",
    dataIndex: "distance",
  },
];

const mostActiveCompanyColums = [
  {
    title: "Bedrijf",
    dataIndex: "name",
  },
  {
    title: "Actieve / totaal medewerkers",
    dataIndex: "totalActiveEmployees",
  },
];

export const CompanyOverview: React.FC<CompanyOverviewProps> = () => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const topCompaniesRef = context.store
    .collection("top_prestaties")
    .doc("bedrijven");
  const [
    topCompaniesDoc,
    loadingTopCompaniesDoc,
    topCompaniesError,
  ] = useDocumentData(topCompaniesRef);

  const companyDistancesCollection = context.store
    .collection("bedrijf_doelen")
    .orderBy("totalGoal.distanceInSportKm", "desc");

  const [
    companyDistances,
    loadingCompanyDistances,
    companyDistancesError,
  ] = useCollectionData(companyDistancesCollection);

  const Loading = () =>
    loadingTopCompaniesDoc &&
    !topCompaniesDoc &&
    loadingCompanyDistances &&
    !companyDistances ? (
      <Loader />
    ) : null;

  const Error = () =>
    (!loadingTopCompaniesDoc &&
      !loadingCompanyDistances &&
      topCompaniesError) ||
    companyDistancesError ? (
      <p style={{ color: "white" }}>
        Er is iets misgegaan, probeer het opnieuw.
      </p>
    ) : null;

  return (
    <div>
      <div
        className="jumbotron text-center"
      >
        <h2>
          Bedrijven wedstrijd
        </h2>
      </div>
      <div className="container">
        <Loading />
        {!loadingTopCompaniesDoc &&
          !loadingCompanyDistances &&
          topCompaniesDoc &&
          companyDistances && (
            <div className="card-wrapper">
              <Row gutter={16}>
                {Object.keys(sortObj(topCompaniesDoc as any)).map(
                  (key, index) => (
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} key={index}>
                      <Card title={setTitle(key)} className="m-2">
                        <List
                          className="individual-list"
                          pagination={{ 
                            pageSize: 5,                   
                            responsive: true,
                            simple: true, 
                           }}
                          dataSource={(topCompaniesDoc as any)[key]}
                          renderItem={(item: any, listKey) => (
                            <List.Item key={listKey}>
                              <List.Item.Meta title={item.name} />
                              <div>{key === 'swimming' ? Math.round(item.distance / 10) : item.distance}km</div>
                            </List.Item>
                          )}
                        ></List>
                      </Card>
                    </Col>
                  )
                )}
                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                  <Card title={"Actiefste bedrijven"} className="m-2">
                    <List
                      className="individual-list"
                      pagination={{
                        pageSize: 5,                   
                        responsive: true,
                        simple: true, 
                       }}
                      dataSource={
                        companyDistances
                          ? (companyDistances as any)
                              .sort(
                                (a: any, b: any) =>
                                  b.employeesPercentage - a.employeesPercentage
                              )
                              .map((item: any) => item)
                          : undefined
                      }
                      renderItem={(item: any, key) => (
                        <List.Item key={key}>
                          <List.Item.Meta title={item.name} />
                          <div>
                            {item.activeEmployees + "/" + item.totalEmployees}
                          </div>
                        </List.Item>
                      )}
                    ></List>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        <Error />
      </div>
    </div>
  );
};
