import React, { useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "../components/Home/Home";
import ProfileScreen from "../components/Profile/ProfileScreen";
import ProtectedRoute from "./ProtectedRoute";
import Navbar from "../components/Layout/Navbar";
import SpelRegels from "../components/Spelregels/index";
import ChallengeExplained from "../components/Spelregels/ChallengeExplained";
import { FirebaseContext } from "../components/FirebaseContext";
import { useAuthState } from "react-firebase-hooks/auth";
import IndividualOverview from "../components/Wedstrijd/IndividualOverView/IndividualOverview";
import { CompanyOverview as TopCompaniesOveview } from "../components/Wedstrijd/CompanyOverview/CompanyOverview";
import { Result } from "antd";
import CompanyOverview from "../components/Bedrijven/CompanyOverview";
import PhotoGallery from "../components/Gallery/Gallery";

export default () => {
  const context = useContext(FirebaseContext);
  const [user] = useAuthState(context.auth);

  return (
    <BrowserRouter>
      {user ? <Navbar /> : null}
      <Switch>
        <Route exact path={"/"} component={Home} />
        <ProtectedRoute path={"/frequently-asked-questions"} component={SpelRegels} />
        <ProtectedRoute path={"/individueel"} component={IndividualOverview} />
        <ProtectedRoute path={"/profiel"} component={ProfileScreen} />
        <ProtectedRoute path={"/bedrijven"} component={TopCompaniesOveview} />
        <ProtectedRoute path={"/bedrijven-en-werknemers"} component={CompanyOverview} />
        <ProtectedRoute path={"/waarom-deze-challenge"} component={ChallengeExplained} />
        <ProtectedRoute path={"/gallerij"} component={PhotoGallery} />
        <Route
          exact
          path={"*"}
          component={() => (
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};
