import { Layout as LayoutComponent } from "antd";
import Navbar from './Navbar';
import React from "react";

export type LayoutProps = {};

const { Content } = LayoutComponent;
const Layout: React.FC = ({ children }) => {
  return (
    <LayoutComponent>
      <Content style={{ minHeight: 200}}>{children}</Content>
    </LayoutComponent>
  );
};

export default Layout;
