import React, { useEffect } from "react";
import { FirebaseProvider } from "./components/FirebaseProvider";
import "fontsource-inter";
import "./App.scss";
import "bootstrap-css-only";
import Router from "./router/Router";
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <div className="App">
      <FirebaseProvider>
        <Layout>
          <Router />
        </Layout>
      </FirebaseProvider>
    </div>
  );
}

export default App;
