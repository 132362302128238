import React, { useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "../FirebaseContext";
import Gallery from "react-photo-gallery";
import { Loader } from "../FirebaseProvider";

type Photos = {
  src: string;
  width: number;
  height: number;
};
const PhotoGallery: React.FC = () => {
  const context = React.useContext(FirebaseContext);
  const photosCollection = context.store
    .collection("photos")
    .orderBy("date", "desc")
    .limit(50);
  const [photos, loadingPhotos, photosError] = useCollection(photosCollection);
  const [galleryPhotos, setGalleryPhotos] = useState<Photos[] | undefined>(
    undefined
  );

  const Loading = () => (loadingPhotos ? <Loader /> : null);

  useEffect(() => {
    if (!loadingPhotos && photos && !photosError) {
      setGalleryPhotos(
        photos.docs.reduce((newPhotos: Photos[], photo: any) => {
          return [
            ...newPhotos,
            {
              src: photo.data().url,
              width: photo.data().width,
              height: photo.data().height,
            },
          ];
        }, [])
      );
    }
  }, [loadingPhotos, photos, photosError]);

  if (photosError)
    return <p>Er is iets misgegaan met het ophalen van de foto's</p>;

  return (
    <div>
      <div className="jumbotron text-center">
        <h2>Gallerij</h2>
      </div>
      <Loading />
      <div style={{ minHeight: "100vh" }}>
        <div className="container">
          {!loadingPhotos && photos?.docs.length > 0 && galleryPhotos && (
            <Gallery photos={galleryPhotos!} direction={"row"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
