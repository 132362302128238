import React from "react";
import { HomeOutlined, UserOutlined, InfoCircleOutlined, BarChartOutlined, CameraOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import Login from "../LoginComponent";
import { useLocation, Link } from "react-router-dom";

export interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  let location = useLocation();
  return (
    <Menu theme="light" mode="horizontal" selectedKeys={[location.pathname]}>
      <Menu.Item key="/" icon={<HomeOutlined />}>
        <Link to="/">Challenge</Link>
      </Menu.Item>
      <Menu.Item key="/bedrijven" icon={<BarChartOutlined/>}>
        <Link to="/bedrijven">Bedrijven</Link>
      </Menu.Item>
      <Menu.Item key="/bedrijven-en-werknemers" icon={<BarChartOutlined/>}>
        <Link to="/bedrijven-en-werknemers">Bedrijven en werknemers</Link>
      </Menu.Item>
      <Menu.Item key="/individueel" icon={<BarChartOutlined/>}>
        <Link to="/individueel">Individueel</Link>
      </Menu.Item>
      <Menu.Item key="/gallerij" icon={<CameraOutlined/>}>
        <Link to="/gallerij">Gallerij</Link>
      </Menu.Item>
      <Menu.Item key="/waarom-deze-challenge" icon={<InfoCircleOutlined/>}>
        <Link to="/waarom-deze-challenge">Waarom deze challenge?</Link>
      </Menu.Item>
      <Menu.Item key="/frequently-asked-questions" icon={<InfoCircleOutlined/>}>
        <Link to="/frequently-asked-questions">FAQ</Link>
      </Menu.Item>
      <div style={{ float: "right", margin: "0 20px" }} key={'login'}>
        <Login />
      </div>
      <Menu.Item key="/profiel" icon={<UserOutlined/>} style={{float: "right"}}>
        <Link to="/profiel">Profiel</Link>
      </Menu.Item>
    </Menu>
  );
};

export default Navbar;
