import { Collapse,List, Image} from "antd";
import * as React from "react";
export interface SpelregelsProps {}
const { Panel } = Collapse;

const data = [
  "# km zwemmen * 10",
  "# km wandelen * 5",
  "# km lopen * 2.5",
  "# km fietsen *1 ",
];

const Spelregels: React.FC<SpelregelsProps> = () => {
  return (
    <div>
      <div className="jumbotron text-center">
        <h2>FAQ</h2>
      </div>

      <div className="container">
        <Collapse className="collapse-list" defaultActiveKey={["1"]}>
        <Panel
            header="Strava richtlijnen"
            key="1"
          >
            <ul className="ul-list">
              <li>
              	Installeer gratis de Strava app op je smartphone of surf naar Strava via je desktop.
              </li>
              <li>
                Verbind naar eigen keuze via Facebook, Google, Apple of je e-mailadres.
              </li>
              <li>
              	Verbind je OECO-profiel met je Strava-profiel door naar je OECO-profiel te gaan. Opgelet! Vink het onderstaande vakje niet uit, anders zullen je activiteiten niet worden meegeteld voor de challenge.
              </li>
              <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Image src="stravainstructions.png"/>
              </div>
              <li>
              	Standaard staan je activiteiten zichtbaar voor iedereen, gelieve dit niet te wijzigen. Zij moeten zichtbaar blijven om mee te tellen voor de challenge. Had je de Strava app al? Ga dan zeker na of je activiteiten zichtbaar staan voor iedereen.
              </li>
              <li>
              	Ga je fietsen, lopen of wandelen? Open dan je Strava app en klik op ‘opnemen’ van zodra je vertrekt, zodat Strava jouw activiteit kan registreren. Ga je zwemmen en heb je geen smartwatch of dergelijke om deze activiteit te registeren? Ga dan naar de app en voer handmatig jouw activiteit in. 
              </li>
              <li>
              	Hoe voer ik handmatig een activiteit in?
                <ul className="ul-list">
                  <li  style={{marginTop: 10}}>
                   Ga naar je feed op de Strava app, klik op het plus-tekentje rechts onderaan.
                  </li>
                  <li>
                  	Kies ‘handmatige activiteit’ en vul alle gegevens in. Klik vervolgens op ‘opslaan’.
                  </li>
                </ul>
              </li>
                <li>
                Toch niet helemaal duidelijk? Vraag dan om te beginnen hulp aan een collega die Strava reeds kent. Mocht ook dit geen raad brengen, mail dan naar <a href="mailto:marjorie.deschepper@cronos.be">marjorie.deschepper@cronos.be</a>. We proberen je dan zo snel mogelijk verder te helpen.
                </li>
            </ul>
            <p>
            </p>
          </Panel>
          <Panel
            header="Wat is de verhouding in kilometers tussen de verschillende disciplines (fietsen, wandelen, lopen en zwemmen)? Waarom?"
            key="2"
          >
            <p>
              Aangezien de intensiteit verschilt per discipline, hebben we
              ervoor gekozen het aantal kilometers te vermenigvuldigen met
              verschillende coëfficënten. Bovendien wordt het mogelijk om alle
              afzonderlijke prestaties per team op te tellen en te vergelijken
              op een gelijke schaal. Dit ziet er dan als volgt uit:
            </p>
            <List
              size="small"
              bordered
              dataSource={data}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Panel>
          <Panel
            header="Is er een verschil in telling van de kilometers op de verschillende dashboards?"
            key="3"
          >
            <p>
              Wanneer we verschillende disciplines vergelijken, zullen wij
              werken met absolute cijfers en dus het daadwerkelijke aantal
              kilometers dat jij aflegde. Wanneer we de verschillende
              teamprestaties vergelijken, zullen we werken met relatieve cijfers
              en dus het aantal kilometers vermenigvuldigd met de gekozen
              coëfficiënt per per discipline. Dit zijn dan de beruchte 'Oecos'.
            </p>
            <p>
              Heb je 10 km gewandeld, 20km gefietst en 5km gezwommen? Dan heb je
              120 Oecos verworven!
            </p>
          </Panel>
          <Panel
            header="Kan ik ten alle tijde deelnemen aan de OECO Challenge?"
            key="4"
          >
            <p>
              Zeker! Hoe meer zielen hoe meer vreugde! Elke prestatie, hoe klein
              deze ook mag zijn, kan het verschil maken voor jouw team op weg
              naar de overwinning.
            </p>
          </Panel>
          <Panel
            header="Wat als ik mijn doel van 500km overschrijd? Worden deze overige kilometers ook meegeteld in mijn individuele prestatie? En in de teamprestatie?"
            key="5"
          >
            <p>
              You are a true hero! Je teamleden zullen je ongetwijfeld dankbaar
              zijn want jouw aantal kilometers blijft gewoon doorlopen, zowel
              binnen het team als voor jou individueel.
            </p>
          </Panel>
          <Panel
            header="Kan ik ook mijn steentje bijdragen zonder mezelf in het zweet te werken?"
            key="6"
          >
            <p>
              Ondanks dat wij jullie graag willen aanzetten tot
              lichaamsbeweging, snappen we dat dit niet voor iedereen zo voor de
              hand liggend is, daarom hebben wij ook kleinere éénmalige
              opdrachten voorzien doorheen de route om ook de minder actieve
              deelnemers te entertainen. Met deze mini challenges kan je
              eveneens kilometers winnen. Heb je leuke suggesties? Mail ze dan
              zeker naar <strong>marjorie.deschepper@cronos.be</strong>
            </p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Spelregels;
 