import React from "react";
import {
  FirebaseContext,
  IFirebaseContext,
} from "../components/FirebaseContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { RouteProps,Route, Redirect } from "react-router-dom";
import { Spin } from "antd";

const ProtectedRoute = (props: RouteProps) => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const [user, loading] = useAuthState(context.auth);
  const { component, ...rest } = props;
  const isAuthenticated = () => !loading && user
  
  if (loading) {
    return <Spin tip="loading..." />;
  }

  if (isAuthenticated()) {
    return (
      <Route
        {...rest}
        component={component}
      />
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default ProtectedRoute;
