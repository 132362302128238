import * as React from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import strava from "strava-v3";
import { StravaAuth } from "../FirebaseProvider";
require('dotenv').config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

strava.config({
  access_token: String(process.env.REACT_APP_STRAVA_ACCESS_TOKEN),
  client_id: String(process.env.REACT_APP_STRAVA_CLIENT_ID),
  client_secret: String(process.env.REACT_APP_STRAVA_CLIENT_SECRET),
  redirect_uri: String(process.env.REACT_APP_STRAVA_REDIRECT_URI),
});

export interface IFirebaseContext {
  firebase: firebase.app.App;
  auth: firebase.auth.Auth;
  store: firebase.firestore.Firestore;
  strava: {
    stravaAuth: StravaAuth,
    setStravaAuth: React.Dispatch<React.SetStateAction<StravaAuth | {}>>
  }
}

export const FirebaseContext = React.createContext({} as IFirebaseContext);
