import React, { SetStateAction, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import { FirebaseContext } from "../FirebaseContext";
import {
  Form,
  Input,
  Button,
  Select,
  Tag,
  message,
  Card,
  Row,
  Col,
  Alert,
} from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import "./style.scss";
import StravaConnectButton from "../Strava/StravaConnectButton";
import {
  ProfileRegistrationStatus,
  StravaStatus,
} from "../Profile/ProfileScreen";
import isEqual from "lodash/isEqual";

const { Option } = Select;

enum Sports {
  Walking = "Wandelen",
  Cycling = "Fietsen",
  Swimming = "Zwemmen",
  Running = "Lopen",
}

export type RegisterProps = {
  setProfileStatus: React.Dispatch<SetStateAction<ProfileRegistrationStatus>>;
} & StravaProps;

export interface StravaProps {
  stravaStatus: StravaStatus;
  statusCallback: React.Dispatch<SetStateAction<any>>;
}

export interface CompanyProps {
  loadingCompanies: boolean;
  companies: any;
  companyError: Error | undefined;
}

const StravaConnection = (props: StravaProps) => {
  const { stravaStatus, statusCallback } = props;
  switch (props.stravaStatus) {
    case StravaStatus.NOT_CONNECTED:
      return (
        <StravaConnectButton
          stravaStatus={stravaStatus}
          statusCallback={statusCallback}
        />
      );
    case StravaStatus.CONNECTING:
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          Processing
        </Tag>
      );
    case StravaStatus.CONNECTED:
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Verbonden
        </Tag>
      );
    case StravaStatus.CONNECTING_ERROR:
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Er is een fout opgetreden
        </Tag>
      );
    default:
      return null;
  }
};

const Register: React.FC<RegisterProps> = (props) => {
  const context = React.useContext(FirebaseContext);
  const [user] = useAuthState(context.auth);
  const userCollection = context.store.collection("gebruikers");
  const companyCollection = context.store.collection("bedrijven");
  const [companies, loadingCompanies, companyError] = useCollection(
    companyCollection
  );

  const handleSubmit = async (values: FormValues) => {
    let sports = [
      { sport: Sports.Cycling, goal: 0 },
      { sport: Sports.Running, goal: 0 },
      { sport: Sports.Swimming, goal: 0 },
      { sport: Sports.Walking, goal: 0 },
    ];
    let strava = context.strava.stravaAuth;
      try {
         await userCollection.doc(user.uid).set({
          name: user.displayName.substr(user.displayName.indexOf(' ')+1),
          firstName: user.displayName.substr(0,user.displayName.indexOf(' ')),
          company: values.company,
          sports: sports,
          strava,
        });
        props.setProfileStatus(ProfileRegistrationStatus.COMPLETE_PROFILE);
        message.success("Profiel succesvol aangemaakt!"); 
      } catch (error) {
        props.setProfileStatus(ProfileRegistrationStatus.INCOMPLETE_PROFILE);
        message.error("Er is iets misgegaan, probeer het opnieuw.");
      }
  };

  return (
    <div>
      <div
        className="jumbotron text-center"
      >
        <h2>
          Registratie voltooien
        </h2>
      </div>
      <div className="container">
        <RegisterForm
          firstName={user.displayName.substr(0,user.displayName.indexOf(' '))}
          lastName={user.displayName.substr(user.displayName.indexOf(' ')+1)}
          onSubmit={handleSubmit}
          loadingCompanies={loadingCompanies}
          companies={companies}
          companyError={companyError}
          stravaStatus={props.stravaStatus}
          statusCallback={props.statusCallback}
        />
      </div>
    </div>
  );
};

const layout = {
  labelCol: { span: 4 },
};

interface Sport {
  goal: number;
  sport: string;
}

interface FormValues {
  company: string;
  goal: number;
  sport: string;
  sports: Sport[] | undefined;
}

type RegisterFormProps = {
  onSubmit: (values: FormValues) => void;
  firstName: string;
  lastName: string;
} & StravaProps &
  CompanyProps;

const RegisterForm = (props: RegisterFormProps) => {
  const [sportCounter, setSportCounter] = useState<number>(0);
  const { onSubmit, firstName, lastName} = props;
  return (
    <Card
      style={{ borderRadius: "7px", paddingTop: "1rem", marginBottom: "3rem" }}
    >
      <Form
        name="dynamic_form_nest_item"
        onFinish={onSubmit}
        autoComplete="off"
      >
        <Row>
          <Col push={6} span={12}>
            <div className="intro">
              <h5>Gegevens</h5>
              Voor dat je naar Amsterdan kan gaan hebben we nog wat gegevens van
              je nodig. Koppel alvast zeker je Strava-account.
            </div>
            <Form.Item {...layout} labelAlign={"left"} label="Voornaam">
              <Input prefix={firstName} disabled />
            </Form.Item>
            <Form.Item {...layout} labelAlign={"left"} label="Achternaam">
              <Input prefix={lastName} disabled />
            </Form.Item>
            {!isEqual(props.stravaStatus, StravaStatus.CONNECTED)?  <Alert message="Toegang verlenen tot het bekijken van activiteitsgegevens is vereist." type="info" showIcon className="mb-3" />: null}
          
            <Form.Item {...layout} labelAlign={"left"} label="Strava">
              <StravaConnection {...props} />
            </Form.Item>
            {isEqual(props.stravaStatus, StravaStatus.CONNECTED) && (
              <Form.Item
                {...layout}
                labelAlign={"left"}
                name="company"
                label="Bedrijf"
                rules={[
                  {
                    required: true,
                    message: "Gelieve een bedrijf te selecteren",
                  },
                ]}
              >
                <Select
                  placeholder="Selecteer"
                  loading={props.loadingCompanies}
                >
                  {props.companies
                    ? props.companies.docs.map(
                        (company: any, index: number) => (
                          <Option value={company.id} key={index}>
                            {company.data().name}
                          </Option>
                        )
                      )
                    : null}
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            disabled={!isEqual(props.stravaStatus, StravaStatus.CONNECTED)}
            type="primary"
            htmlType="submit"
            size="large"
          >
            Voltooien
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Register;
