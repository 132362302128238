import {
  message,
  Button,
  Form,
  InputNumber,
  PageHeader,
  Spin,
  Row,
  Col,
  Table,
  Card,
} from "antd";
import { BarChartOutlined, EditOutlined, LineChartOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { FirebaseContext, IFirebaseContext } from "../../FirebaseContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { ProgressStatus } from "../ProfileScreen";

export interface ProfileOverviewProps {}

export enum Factor {
  Walking = 5,
  Cycling = 1,
  Swimming = 10,
  Running = 2.5,
}

export enum Sport {
  Zwemmen = "Zwemmen",
  Fietsen = "Fietsen",
  Wandelen = "Wandelen",
  Lopen = "Lopen",
}

const columns = [
  {
    title: "Sport",
    dataIndex: "sport",
  },
  {
    title: "Afstand (km)",
    dataIndex: "distanceInKm",
  },
  {
    title: "Doel (km)",
    dataIndex: "goalInKm",
  },
  {
    title: "Doel bereikt (%)",
    dataIndex: "goalReached",
  },
];

const rankingColumns = [
  {
    title: "Sport",
    dataIndex: "sport",
  },
  {
    title: "Deelnemers",
    dataIndex: "competitors",
  },
  {
    title: "Rank",
    dataIndex: "position",
  },
];

const ProfileOverview: React.FC<ProfileOverviewProps> = () => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const [user] = useAuthState(context.auth);
  const rankingRef = context.store
    .collection("top_prestaties")
    .doc("gebruikers");
  const userGoalsCollection = context.store.collection("gebruiker_doelen");
  const userGoalsDocumentRef = userGoalsCollection.doc(user.uid);
  const [
    rankingDoc,
    loadingRankingDoc,
    RankingDocError,
  ] = useDocumentData(rankingRef);

  const [
    userGoalsDoc,
    loadingUserGoalsDoc,
    userGoalsDocError,
  ] = useDocumentData(userGoalsDocumentRef);

  const [
    submitProgressStatus,
    setSubmitProgressStatus,
  ] = useState<ProgressStatus>(ProgressStatus.Uninitialized);

  const [
    userGoalsrogressStatus,
    setUserGoalsProgressStatus,
  ] = useState<ProgressStatus>(ProgressStatus.Uninitialized);

  const [
    rankingrogressStatus,
    setRankingProgressStatus,
  ] = useState<ProgressStatus>(ProgressStatus.Uninitialized);

  const setSportType = (type: Sport) => {
    switch(type) {
      case Sport.Fietsen:
        return 'cycling';
      case Sport.Lopen:
        return 'running';
      case Sport.Wandelen:
        return 'walking';
      case Sport.Zwemmen:
        return 'swimming'
    }
  }

  const setDistanceInSportKm = (type: Sport, distance: number) => {
    switch (type) {
      case Sport.Wandelen:
        return Math.round(distance * Factor.Walking);
      case Sport.Lopen:
        return Math.round(distance * Factor.Running);
      case Sport.Zwemmen:
        return Math.round(distance * Factor.Swimming);
      case Sport.Fietsen:
        return Math.round(distance * Factor.Cycling);
    }
  };

  const sum = (total: number, num: number) => total + num;

  const percentage = (value: number, total: number) =>
    Math.round((value / total) * 100);

  const onFormSubmit = async (values: any) => {
    setSubmitProgressStatus(ProgressStatus.InProgress);
    let currentUserGoals = (userGoalsDoc as any).goals;
    let currentUserTotalGoal = (userGoalsDoc as any).totalGoal;
    let newUserTotalGoal = {
      distanceGoalInKm: 0,
      distanceGoalInSportKm: 0,
      distanceGoalReached: 0,
      distanceInKm: currentUserTotalGoal.distanceInKm,
      distanceInSportKm: currentUserTotalGoal.distanceInSportKm,
    };
    let newUserGoals = Object.keys(values).map((value) => ({
      sport: value,
      goal: values[value],
    }));
    let updatedGoals = currentUserGoals.map((goal: any, key: number) => {
      let index = newUserGoals.findIndex(
        (userGoal: any) => userGoal.sport === goal.sport
      );
      let newGoalInSportKm = setDistanceInSportKm(
        newUserGoals[index].sport as Sport,
        newUserGoals[index].goal
      );
      let goalReached = percentage(goal.distanceInSportKm, newGoalInSportKm);
      return {
        ...goal,
        goalInKm: newUserGoals[index].goal,
        goalInSportKm: newGoalInSportKm,
        goalReached: goalReached !== goalReached ? 0 : goalReached,
      };
    });
    newUserTotalGoal.distanceGoalInSportKm = updatedGoals
      .map((goal: any) => goal.goalInSportKm)
      .reduce(sum);
    newUserTotalGoal.distanceGoalInKm = updatedGoals
      .map((goal: any) => goal.goalInKm)
      .reduce(sum);
      let userTotalGoalReached =  percentage(
        newUserTotalGoal.distanceInSportKm,
        newUserTotalGoal.distanceGoalInSportKm
      );
    newUserTotalGoal.distanceGoalReached = userTotalGoalReached !== userTotalGoalReached ? 0 : userTotalGoalReached;
    try {
      await userGoalsDocumentRef.update({
        goals: updatedGoals,
        totalGoal: newUserTotalGoal,
      });
      setSubmitProgressStatus(ProgressStatus.Done);
    } catch (error) {
      setSubmitProgressStatus(ProgressStatus.Error);
    }
  };

  const Loader = () => (
    <Spin className="centered-loader" tip="Even geduld..." />
  )

  const Error = () => (          
    <p>
      Er is iets misgegaan, probeer het opnieuw.
    </p>
)

  useEffect(() => {
    switch (submitProgressStatus) {
      case ProgressStatus.Done:
        message.success("Doelstellingen succesvol aangepast");
        setSubmitProgressStatus(ProgressStatus.Uninitialized);
        break;
      case ProgressStatus.Error:
        message.error("Er is iets misgelopen, probeer het opnieuw");
        break;
      default:
        break;
    }
  }, [submitProgressStatus]);

  useEffect(() => {
    if (loadingUserGoalsDoc || (loadingUserGoalsDoc && !userGoalsDoc && !userGoalsDocError)) {
      setUserGoalsProgressStatus(ProgressStatus.InProgress);
    }
    if (!loadingUserGoalsDoc && userGoalsDoc && !userGoalsDocError) {
      setUserGoalsProgressStatus(ProgressStatus.Done);
    }
    if (!loadingUserGoalsDoc && !userGoalsDoc && userGoalsDocError) {
      setUserGoalsProgressStatus(ProgressStatus.Error);
    }
  }, [loadingUserGoalsDoc, userGoalsDocError, userGoalsDoc]);

  useEffect(() => {
    if(loadingRankingDoc) {
      setRankingProgressStatus(ProgressStatus.InProgress);
    }
    if(!loadingRankingDoc && rankingDoc && !RankingDocError) {
      setRankingProgressStatus(ProgressStatus.Done)
    }
    if(!loadingRankingDoc && !rankingDoc || RankingDocError) {
      setRankingProgressStatus(ProgressStatus.Error)
    }
  }, [rankingDoc,loadingRankingDoc,RankingDocError])

  const PersonalRanking = () => {
    switch(rankingrogressStatus) {
      case ProgressStatus.InProgress:
        return <Loader/>
      case ProgressStatus.Done:
        return (
          <Card size="small">
            <Table
            pagination={false}
            bordered
            columns={rankingColumns}
            dataSource={
              userGoalsDoc &&
              (userGoalsDoc as any).goals.map(
                (value: any, index: number) => {
                  let sportKey = setSportType(value.sport);
                  let position = (rankingDoc as any)[sportKey].findIndex((item: any) => item.id === user.uid);
                  return {
                    sport: value.sport,
                    competitors: (rankingDoc as any)[sportKey].length,
                    position: position === -1 ? (rankingDoc as any)[sportKey].length: position + 1,
                    key: index,
                  }
                }
              )
            }
            size="small"
          />
          </Card>
        )
      case ProgressStatus.Error:
        return (<Error/>)
      default: return null;
    }
  }

  const ProfileView = () => {
    switch (userGoalsrogressStatus) {
      case ProgressStatus.InProgress:
        return <Loader/>;
      case ProgressStatus.Done:
        return (
          <div className="container">
            <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div>
                  <PageHeader
                    title="Rankschikking"
                    subTitle={<LineChartOutlined />}
                  />
                  <div style={{ padding: "0px 24px"}}>
                  <PersonalRanking/>
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <PageHeader
                  style={{ color: "white" }}
                  title="Persoonlijke progressie"
                  subTitle={<BarChartOutlined />}
                />
                <Row>
                  <Col
                    span={24}
                    style={{ color: "white", fontWeight: 'bold', padding: "0px 24px" }}
                  >
                    <p>
                      <strong>
                        {percentage(
                          (userGoalsDoc as any).totalGoal.distanceInSportKm,
                          500
                        ) > 100
                          ? 100
                          : percentage(
                              (userGoalsDoc as any).totalGoal.distanceInSportKm,
                              500
                            )}
                        %
                      </strong>
                      <span> </span>
                      van de <strong>500 Oecos</strong> afgelegd.
                    </p>
                    <p>
                      Uw totale prestatie in Oecos is:{" "}
                      <strong>
                        {(userGoalsDoc as any).totalGoal.distanceInSportKm}{" "}
                        Oecos{" "}
                      </strong>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        );
      case ProgressStatus.Error:
        return (
          <Error/>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <div
        className="jumbotron text-center"
      >
        <h2>Profiel</h2>
      </div>
      <ProfileView />
    </div>
  );
};

export default ProfileOverview;
