import React, { useEffect, useState } from "react";
import { Card, Col, List, Progress, Row, Select } from "antd";
import { FirebaseContext, IFirebaseContext } from "../FirebaseContext";
import { useCollection } from "react-firebase-hooks/firestore";
import { ProgressStatus } from "../Profile/ProfileScreen";
import { Loader } from "../FirebaseProvider";
const { Option } = Select;

interface SelectedCompany {
  id: string;
  value: string;
}

interface CompanyProgressProps {
  getCompanyProgress: ProgressStatus;
  companyGoals: any;
  companyMembersGoals: any;
}
export interface CompanyOverviewProps {}

const CompanyProgress = (props: CompanyProgressProps) => {
  const { getCompanyProgress, companyGoals, companyMembersGoals } = props;
  switch (getCompanyProgress) {
    case ProgressStatus.InProgress:
      return <Loader />
    case ProgressStatus.Done:
      return (
        <div className="mt-4">
          <div className="card-bedrijf-wrapper mb-4">
            <Card title={companyGoals.name}>
              {companyGoals.goals.map((goal: any) => (
                <Row gutter={10}>
                  <Col xs={5} sm={5} md={5} lg={3} xl={2} className="">
                    <span style={{ fontWeight: "bold" }}>{goal.sport}</span>
                  </Col>
                  <Col xs={19} sm={19} md={19} lg={21} xl={22}>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      {goal.distanceInKm} km
                    </span>
                  </Col>
                </Row>
              ))}
            </Card>
          </div>
          <div className="card-werknemers-wrapper mb-4">
            <Card title="Werknemers" bordered={false}>
              <List
                itemLayout="horizontal"
                grid={{ gutter: 16, column: 2 }}
                dataSource={companyMembersGoals}
                pagination={{
                  pageSize: 4,
                }}
                renderItem={(member: any, key: number) => (
                  <List.Item key={key}>
                    <Card
                      type="inner"
                      title={member.name}
                      className="mb-3"
                      key={key}
                    >
                      {member.goals.map((goal: any) => (
                        <Row gutter={10}>
                          <Col xs={12} sm={12} md={8} lg={6} xl={4} className="pr-3">
                            <span style={{ fontWeight: "bold" }}>
                              {goal.sport}
                            </span>
                          </Col>
                          <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {goal.sport === 'Zwemmen' ? Math.round(goal.distanceInKm / 10) : goal.distanceInKm} km
                            </span>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </List.Item>
                )}
              />
            </Card>
          </div>
        </div>
      );
    case ProgressStatus.Error:
      return <p style={{color: "white"}}>Er is iets misgegaan, probeer het opnieuw.</p>;
    case ProgressStatus.Uninitialized:
      return null;
  }
};

const CompanyOverview: React.FC<CompanyOverviewProps> = () => {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  const companyCollection = context.store.collection("bedrijven");
  const companyGoalsCollection = context.store.collection("bedrijf_doelen");
  const [companies, loadingCompanies, companyError] = useCollection(
    companyCollection
  );
  const [selectedCompany, setSelectedCompany] = useState<
    SelectedCompany | undefined
  >(undefined);
  const [getCompanyProgress, setGetCompanyProgress] = useState<ProgressStatus>(
    ProgressStatus.Uninitialized
  );

  const [companyMembersGoals, setCompanyMembersGoals] = useState<any>(
    undefined
  );
  const [companyGoals, setCompanyGoals] = useState<any>(undefined);

  useEffect(() => {
    const getCompanyMembers = async (selectedCompany: SelectedCompany) => {
      setGetCompanyProgress(ProgressStatus.InProgress);
      try {
        let companyGoalDoc = await companyGoalsCollection
          .doc(selectedCompany.id)
          .get();
        if (companyGoalDoc.exists) setCompanyGoals(companyGoalDoc.data());
      } catch (error) {
        setCompanyGoals(undefined);
        setCompanyMembersGoals(undefined);
        setGetCompanyProgress(ProgressStatus.Error);
      }
    };
    if (selectedCompany) {
      getCompanyMembers(selectedCompany);
    } else {
      setGetCompanyProgress(ProgressStatus.Uninitialized);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (
      companyGoals &&
      selectedCompany &&
      getCompanyProgress === ProgressStatus.InProgress
    ) {
      let companyIndex = companies.docs.findIndex(
        (company: any) => company.id === selectedCompany.id
      );
      if (
        companies.docs[companyIndex].data().members &&
        companies.docs[companyIndex].data().members.length > 0
      ) {
        Promise.all(
          companies.docs[companyIndex]
            .data()
            .members.map((member: any) =>
              context.store.collection("gebruiker_doelen").doc(member).get()
            )
        )
          .then((docs) => {
            let members = docs.map((doc: any) => doc.data());
            setCompanyMembersGoals(members);
            setGetCompanyProgress(ProgressStatus.Done);
          })
          .catch((error) => {
            setCompanyGoals(undefined);
            setCompanyMembersGoals(undefined);
            setGetCompanyProgress(ProgressStatus.Error);
          });
      } else {
        setCompanyMembersGoals(undefined);
        setGetCompanyProgress(ProgressStatus.Done);
      }
    }
  }, [companyGoals]);
  return (
    <div>
      <div
        className="jumbotron text-center"
        style={{ background: "rgb(34 34 68)", height: "1OOpx" }}
      >
        <h2 style={{ color: "white", fontWeight: "bold" }}>
          Bedrijven overzicht
        </h2>
      </div>
      <div className="container">
        <Select
          listHeight={200}
          showSearch
          allowClear
          style={{ width: "100%" }}
          placeholder="Zoek voor bedrijven"
          size="large"
          value={selectedCompany ? selectedCompany.value : selectedCompany}
          loading={loadingCompanies}
          onSelect={(value: any, option: any) => {
            setSelectedCompany({ id: option.key, value: option.value });
          }}
          onClear={() => setSelectedCompany(undefined)}
        >
          {companies
            ? companies.docs.map((company: any, index: number) => (
                <Option value={company.data().name} key={company.id}>
                  {company.data().name}
                </Option>
              ))
            : null}
        </Select>
        <CompanyProgress
          getCompanyProgress={getCompanyProgress}
          companyGoals={companyGoals}
          companyMembersGoals={companyMembersGoals}
        />
      </div>
    </div>
  );
};

export default CompanyOverview;
