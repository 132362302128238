import { Avatar, Button, Col, List, Row, Skeleton } from "antd";
import firebase from "firebase";
import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseContext } from "../../FirebaseContext";
import "./style.scss";

const data = [
  {
    title: "Urszula Boeymans",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  },
  {
    title: "Daan Boschmans",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];

const GuestScreen = (props: any) => {
  const context = useContext(FirebaseContext);
  const provider = new firebase.auth.OAuthProvider("microsoft.com");
  let history = useHistory();

  const signInWithMicrosoft = () => {
    context.auth.signInWithPopup(provider).then(() => {
      history.push("/");
    });
  };
  return (
    <Row justify="center" align="middle" className="hero">
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Col span={24}>
          <h1 className="hero-title">De OECO challenge</h1>
        </Col>
        <Col
          span={24}
          style={{
            maxWidth: 780,
            color: "white",
            background: "rgba(0, 0, 0, 0.262)",
            padding: "10px",
            borderRadius: "7px",
            fontSize: "calc(0.5em + 1vmin)",
          }}
        >
        <p>Happy you made it this far! Vanwaar deze challenge vraag je je af?</p>
        <p>Wel, zoals je zelf al weet zit OECO vol inspirerende ondernemers die zichzelf graag voortdurend uitdagen met nieuwe doelstelllingen. Bijgevolg willen wij dit vertalen naar het fysieke en mentale welzijn van onze mensen. What better way than moving everyone in the same direction! Letterlijk en figuurlijk.</p>
        <p>Met deze challenge willen we iedereen samen in beweging brengen richting één doel, één bestemming met onderweg hier en daar een entertaining obstakel, enerzijds om elkaar beter te leren kennen, anderzijds om elkaar te inspireren met de verhalen over wat jullie doen om een gezonde levensstijl na te streven. </p>
        <p>Doe je dit door elke ochtend al jonglerend je bed uit te springen? Misschien fiets of wandel je naar het werk? Prepareer je elke avond een lekkere lunch zodat je de dag nadien een gezonde maaltijd kan nuttigen? Of misschien is het zo simpel dat je enkele squats doet voor je pc, terwijl je aan het bellen bent met je collega?</p>
        <p>Het hoeft niet tijdrovend of zwaar te zijn. Als je kleine gewoontes kan inbouwen in je leven die het nuttige aan het aangename koppelen en bijdragen tot een gezonde geest in een gezond lichaam, dan ben je nu al de geschikte kandidaat om deel te nemen aan deze OECO Challenge! Because no matter how slow you are, you’re still lapping everybody on the couch.</p>
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <Button
            type="primary"
            shape="round"
            size="large"
            style={{ marginTop: "10px", fontWeight: "bold" }}
            onClick={signInWithMicrosoft}
          >
            Doe mee aan de challenge
          </Button>
        </Col>
      </div>
    </Row>
  );
};

export default GuestScreen;
