import React, { useEffect } from "react";
import strava from "strava-v3";
import { Button } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { FirebaseContext } from "../../FirebaseContext";

import "./style.css";
import { StravaStatus } from "../../Profile/ProfileScreen";
import { useHistory, useLocation } from "react-router-dom";

export interface StravaConnectButtonProps {
  statusCallback: Function;
  stravaStatus: StravaStatus;
}

const StravaConnectButton: React.FC<StravaConnectButtonProps> = (props) => {
  const context = React.useContext(FirebaseContext);
  const [authState] = useAuthState(context.auth);
  const userRef = context.store.collection("gebruikers");
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    if (code) {
      props.statusCallback(StravaStatus.CONNECTING);
      registerStrava(code);
    }
  }, [location]);

  function registerStrava(code: string) {
    if (code) {
      strava.oauth
        .getToken(code)
        .then(function (body) {
          addStravaData(authState.uid, body);
          props.statusCallback(StravaStatus.CONNECTED);
         // history.replace({search: ""})
        })
        .catch(function (err) {
          props.statusCallback(StravaStatus.CONNECTING_ERROR);
        });
    }
  }

  //function to navigate to the strava auth webpage
  async function stravaAuth() {
    let url = await strava.oauth.getRequestAccessURL(strava.config);
    url = url + "&scope=activity:read";
    window.location.replace(url);
  }

  //function to add stravaAuth data to gebruiker collection
  function addStravaData(uid: string, body: any) {
    context.strava.setStravaAuth({
      accessToken: body["access_token"],
      refreshToken: body["refresh_token"],
      expiresAt: body["expires_at"],
      athleteId: body.athlete.id,
      photo: body.athlete.profile_medium
    })
  }

  return (
    <Button className="strava-btn" type="default" onClick={stravaAuth}>
      Verbind met Strava
    </Button>
  );
};

export default StravaConnectButton;
