import { Card, Col, Row, Skeleton, Image } from "antd";
import * as React from "react";
export interface ChallengeExplainedProps {}

const ChallengeExplained: React.FC<ChallengeExplainedProps> = () => {
  return (
    <div style={{ height: "100%" }}>
      <div className="jumbotron text-center">
        <h2>Waarom deze challenge?</h2>
      </div>
      <div className="container">
        <Row justify="center">
          <Col span={24}>
            <Card style={{ width: "100%" }}>
              <p>
                Zoals jullie wel weten zijn het vreemde tijden... Corona, al van
                gehoord?{" "}
              </p>
              <p>
                Intussen teistert het virus ons leven al meer dan een jaar en
                geraken we stilletjes aan uitgeput door alle regeltjes die we
                moeten volgen en alle zaken die we vooral NIET mogen doen. Maar
                het moet gezegd worden dat het niet allemaal slecht is!
                Integendeel, geen twijfel dat er velen onder jullie actief
                willen blijven en zo leuke wandelingen, fietstochten, ... in
                eigen streek hebben ontdekt.
              </p>
              <p>
                Het heeft er eveneens voor gezorgd dat we creatief moesten
                worden op vlak van communicatie. We zijn tegenwoordig niet meer
                verbaasd wanneer die ene collega weeral eens op ‘mute’ staat of
                er een huisdier elegant voor de webcam komt paraderen. Bij OECO
                dachten we daarom, laat ons een challenge organiseren om mensen
                opnieuw dichter bij elkaar te brengen, actief te zijn en elkaar
                te leren kennen.
              </p>
              <p>
                Via deze weg willen we zo veel mogelijk mensen aanmoedigen om
                een gezonde levensstijl na te streven, hetzij door intens te
                sporten, hetzij door andere kleine gewoontes die bijdragen tot
                een gezonde geest in een gezond lichaam. Want een gezond mens
                betekent ook een gezonde werknemer!
              </p>
              <p>
                Onderweg hebben we trouwens nog leuke uitdagingen in petto voor
                de minder actieve deelnemers. Hou de communicatie dus goed in de
                gaten want ook daarmee kan je veel OECOS verdienen!{" "}
              </p>
              <p>
                Toch willen we benadrukken dat niets moet, en alles mag. Wij
                horen graag jouw mening. Heb je bijvoorbeeld leuke suggesties of
                opmerkingen over hoe het beter kan? Twijfel dan niet en mail
                deze naar{" "}
                <a href="mailto:marjorie.deschepper@cronos.be">marjorie.deschepper@cronos.be</a>
                .{" "}
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image src="challenge-motivation.png"/>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChallengeExplained;
