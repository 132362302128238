import React, { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { FirebaseContext } from "../FirebaseContext";
import GuestScreen from "../Home/HomeScreen/index";
import AuthHome from "../Home/AuthHomeScreen/AuthHome";

export interface HomeProps {
    
}
 
const Home: React.FC<HomeProps> = () => {
    const context = useContext(FirebaseContext);
    const [user] = useAuthState(context.auth);
    if(user) return <AuthHome />
    return <GuestScreen/>
}
 
export default Home;