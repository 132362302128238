import * as React from "react";
import { FirebaseContext, IFirebaseContext } from "../FirebaseContext";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

export interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const context: any = React.useContext(FirebaseContext);
  const [user] = useAuthState(context.auth);
  return (
    <div>
      <div>{user ? null : <SignInComponent />}</div>
      {}
    </div>
  );
};

export default Login;

function SignInComponent() {
  const context: IFirebaseContext = React.useContext(FirebaseContext);
  let history = useHistory();

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    context.auth.signInWithPopup(provider);
  };

  const signInWithMicrosoft = () => {
    const provider = new firebase.auth.OAuthProvider("microsoft.com");
    context.auth.signInWithPopup(provider).then(() => {
      history.push('/')
    }
    );
  };

  return (
    <Button type="primary" onClick={signInWithMicrosoft}>
     Aanmelden
    </Button>
  );
}

function SignOutComponent() {
  const context: any = React.useContext(FirebaseContext);
  let history = useHistory();
  const signOut = () => {
    context.auth.signOut().then(() => {history.push('/')});
  };

  return (
    <>
      <Button danger type="primary" onClick={(e) => signOut()}>
        Afmelden
      </Button>
    </>
  );
}
